import Link from "next/link"
import styles from "./index.module.css"
import AppLayout from "@components/layout/AppLayout"

function IndexPage() {
  return (
    <AppLayout title="Front" robots="noindex,nofollow">
      <ul className={styles.contentList}>
        <li>
          <h2>Tools</h2>
          <ul>
            <li>
              <Link href="/tools/chart-code">Chart code generation tool</Link>
            </li>
          </ul>
        </li>
        <li>
          <h2>Embeds</h2>
          <ul>
            <li>
              <Link href="/embed/stock-prices-box">Stock Prices Box</Link>
            </li>
            <li>
              <Link href="/embed/stock-prices-table">Stock Prices Table</Link>
            </li>
            <li>
              Chart:&nbsp;
              <Link href="/embed/chart?ids=245,281,165,208">using fuw company ids (mastertable)</Link>
              &nbsp;|&nbsp;
              <Link href="/embed/chart?ids=1200526-4-1,1203204-4-1,134020-4-1,1057075-4-1">
                using wfg ids (old way)
              </Link>
            </li>
            <li>
              <Link href="/embed/premarket">JB Premarket</Link>
            </li>
          </ul>
        </li>
        <li>
          <h2>API</h2>
          <ul>
            <li>
              <Link href="/api/app-fuw-jetzt/front">Front-API for App «FuW jetzt»</Link>
            </li>
          </ul>
        </li>
        <li>
          <h2>Embeds on old systems</h2>
          <ul>
            <li>
              <Link href="https://wp.fuw.ch/buchshop-standalone">Bookshop</Link>
            </li>
            <li>
              <Link href="https://tools.fuw.ch/ig/pmi">PMI heatmap</Link>
            </li>
          </ul>
        </li>
      </ul>
    </AppLayout>
  )
}

export default IndexPage

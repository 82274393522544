import React from "react"
import Head from "next/head"
import Script from "next/script"

type LayoutProps = {
  title: string
  children: React.ReactNode
  robots?: string
}

const AppLayout = ({ title, children, robots }: LayoutProps) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      {children}
      <Script src="https://www.fuw.ch/_static/dnd_iframe_autofit_child.js" strategy="beforeInteractive" />
    </div>
  )
}

if (typeof window === "object") {
  // Dark Mode, Step 1:
  // we are checking/setting system dark mode by ourselves (this must be before step 2!)
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    if (document.body) {
      document.body.classList.add("dark")
    }
  }

  // Dark Mode, Step 2:
  // registering message events from Disco
  window.addEventListener("message", (event: any) => {
    if (event.origin.indexOf("fuw.ch") !== -1 && event.data.type === "colorMode") {
      if (event.data.mode === "dark") {
        document.body.classList.add("dark")
      } else {
        document.body.classList.remove("dark")
      }
    }
  })
}

export default AppLayout
